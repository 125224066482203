import axios from 'axios'

let baseURL = 'https://isc.360.com/webapi'
// let baseURL = 'http://isc.360zqaq.net/webapi'
// if (import.meta.env.MODE === 'test') {
//   baseURL = 'http://api.isc.360zqaq.net'
// } else {
//   baseURL = 'https://isc.360.com/webapi'
// }

// console.log('baseUrl->', import.meta.env.MODE)
const request = axios.create({
  baseURL,
  timeout: 5000
})

export default request
